<template>
  <tr>
    <!-- <td>
      <img v-if="comercio.imagen != null" :src="imagenRuta" :alt="`${comercio.nombre}`">
      <img v-else src="@/assets/image-not-available.jpg" :alt="`${comercio.nombre}`">
    </td> -->
    <td>{{ servicio.servicio_nombre }}</td>
    <td>{{ servicio.categoria_nombre }}</td>
    <td>{{ servicio.stock_total }} unidades</td>
    <td class="no-padding">
      <ul>
        <!-- <li v-if="servicio.diferencia_stock != 0" class="error-stock">HAY UN ERROR EN EL STOCK. LAS PERSONAS NO PUEDEN TENER MAS UNIDADES QUE EL STOCK TOTAL</li> -->
        <StockListaItemPersona
          v-for="persona in servicio.personas"
          :persona="persona"
          :key="persona.persona_id"
        ></StockListaItemPersona>
      </ul>
      <!-- <table class="tabla-distribucion">
        <thead>
          <th class="borde-derecha">Depósito AAYI</th>
          <th>Personas</th>
        </thead>
        <tbody>
          <tr>
            <td class="borde-derecha">{{ servicio.sotck_deposito_aayi }} unidades</td>
            <td class="no-padding">
              <table>
                <thead>
                  <th class="borde-derecha">Nombre</th>
                  <th>Stock</th>
                </thead>
                <tbody>
                  <StockListaItemPersona
                    v-for="persona in servicio.personas"
                    :persona="persona"
                    :key="persona.persona_id"
                  ></StockListaItemPersona>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table> -->
    </td>
<!--     <td>{{ servicio.certificacion.nombre }}</td>
    <td>{{ servicio.tipo.nombre }}</td> -->
    <td>
      <div class="contenor_botones_form_stock">
        <router-link :to="`/editar-stock/${servicio.servicio_id}`" class="boton-editar">Editar</router-link>
        <button @click="borrarServicio(servicio.servicio_id)" class="boton-borrar">Borrar</button>
      </div>
    </td>
    <!-- <td class="botones-acciones"> -->
      <!-- <div class="botones-acciones-contenedor"> -->
        <!-- <button @click="abrirModal(profesor.persona_id)" class="icon icon-trash-o boton-acciones">Dar de Baja</button> -->
      <!-- </div> -->
      <!-- <div :class="`confirmation-${profesor.persona_id}`" class="confirmation">
        <div class="contenor_modal">
          <p>¿Estas seguro que deseas dar de baja a {{ profesor.nombre }} {{ profesor.apellido }}</p>
          <div class="contenor_modal_botones">
            <button @click="cerrarModal(profesor.persona_id)" class="boton_cancelar boton_modal">CANCELAR</button>
            <button @click="bajaProfesor(profesor.persona_id)" :data-id="`${profesor.persona_id}`" class="boton_aceptar boton_modal">ACEPTAR</button>
          </div>
        </div>
      </div> -->
    <!-- </td> -->
  </tr>
</template>

<script>
import StockListaItemPersona from '../components/StockListaItemPersona.vue'
import serviciossService from '../services/servicios'
export default {
  name: 'StockListaItem',
  components: {
    StockListaItemPersona
  },
  props: ['servicio'],
  data: function () {
    return {
      // store
    }
  },
  methods: {
    borrarServicio (id) {
      console.log(id)
      serviciossService
        .borrarServicio(id)
        .then(respuesta => {
          this.$emit('refrescar')
        })
    },
    abrirModal (id) {
      console.log(id)
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'flex'
    },
    cerrarModal (id) {
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'none'
    }
    // tipo (tipo) {
    //   switch (tipo) {
    //     case 1:
    //       return 'comercio_tipo_restaurant'
    //     case 2:
    //       return 'comercio_tipo_hotel'
    //     case 3:
    //       return 'comercio_tipo_bar'
    //   }
    //   return 'comercio_tipo_restaurant'
    // }
  },
  computed: {
    // imagenRuta: function () {
    //   return `${PATH_IMG}/${this.comercio.imagen}`
    // }
  }
}
</script>

<style>

.no-padding {
  padding: 0 !important;
}

.tabla-distribucion td, .tabla-distribucion th {
  border: 0 !important;
}

.tabla-distribucion th {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background-color: #0984e3 !important;
}

#tabla-stock ul {
  list-style-type: none;
}

#tabla-stock ul li {
  margin: 12px 0;
}

.error-stock {
  background-color: red;
  color: white;
}

.boton-editar {
  padding: 8px;
  color: white;
  background-color: blue;
  border-radius: 5px;
  text-decoration: none;
  font-size: 12px;
  border: 1px solid blue;
  margin: 5px 0;
}

.boton-borrar {
  padding: 8px;
  color: white;
  background-color: red;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid red;
  cursor: pointer;
  margin: 5px 0;
}

.contenor_botones_form_stock {
  height: auto;
  display: flex;
  flex-direction: column;
}
</style>
