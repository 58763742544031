import { API } from './../constants'
import authService from './auth.js'
let productos = null

const productosService = {
  /**
   * Retorna un array con todos los profesores
   *
   * @return {Promise<{}>}
   */
  async traerProductosTodos () {
    const fetchResponse = await fetch(`${API}/productos`)
    const respuesta = await fetchResponse.json()
    productos = respuesta.data
    return [...productos]
  },
  async traerProductossTodosConStock () {
    const fetchResponse = await fetch(`${API}/productos-puntos-de-venta`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    productos = respuesta.data
    return [...productos]
  }
}

export default productosService
