<template>
  <div>
    <div class="contenedor-titulo">
      <h1>Crear Socio</h1>
      <div class="barra"></div>
    </div>
    <div>
      <form
        action="#"
        method="post"
        @submit.prevent="crearSocio(socio)"
        class="form_presupuesto_nuevo"
      >
        <div class="form_section">
          <label for="nombre">Nombre</label>
          <input
            type="text"
            name="nombre"
            id="nombre"
            v-model="socio.nombre"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.nombre !== null"
          >
            {{ errores.nombre[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="apellido">Apellido</label>
          <input
            type="text"
            name="apellido"
            id="apellido"
            v-model="socio.apellido"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.apellido !== null"
          >
            {{ errores.apellido[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="email">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            v-model="socio.email"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.email !== null"
          >
            {{ errores.email[0] }}
          </div>
        </div>
        <div class="form_section_editar_socio">
          <label for="dni">DNI</label>
          <input
            type="text"
            name="dni"
            id="dni"
            v-model="socio.dni"
            :disabled="cargando"
          >
          <div
            class="form_editar_mensaje_error"
            v-if="errores.dni !== null"
          >
            {{ errores.dni[0] }}
          </div>
        </div>

        <div class="form_section">
          <label for="perfil_id">Perfil</label>
          <select
            id="perfil_id"
            name="perfil_id"
            class="form minimal"
            v-model.number="socio.perfil_id"
            :disabled="cargando"
          >
            <option
              v-for="perfil in perfiles"
              :value="perfil.perfil_id"
              :key="perfil.perfil_id"
            >
              {{ perfil.nombre }}
            </option>
          </select>
          <div
          class="form_mensaje_error"
            v-if="errores.perfil_id !== null"
          >
            {{ errores.perfil_id[0] }}
          </div>
        </div>
        <div
          class="form_section"
          v-if="mostrarCampoCertificacion"
        >
          <label for="certificacion_id">Certificacion</label>
          <select
            id="certificacion_id"
            name="certificacion_id"
            class="form minimal"
            v-model.number="socio.certificacion_id"
            :disabled="cargando"
          >
            <option
              v-for="certificacion in certificaciones"
              :value="certificacion.certificacion_id"
              :key="certificacion.certificacion_id"
            >
              {{ certificacion.nombre }}
            </option>
          </select>
          <div
          class="form_mensaje_error"
            v-if="errores.certificacion_id !== null"
          >
            {{ errores.certificacion_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="fecha_ingreso">Fecha de Ingreso</label>
          <input
            type="date"
            name="fecha_ingreso"
            id="fecha_ingreso"
            v-model="socio.fecha_ingreso"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.fecha_ingreso !== null"
          >
            {{ errores.fecha_ingreso[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="socio_numero"># de socio</label>
          <input
            type="text"
            name="socio_numero"
            id="socio_numero"
            v-model="socio.socio_numero"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.socio_numero !== null"
          >
            {{ errores.socio_numero[0] }}
          </div>
        </div>
        <!-- <div class="form_section">
          <label for="pago_id">Forma de Pago</label>
          <select
            id="forma_de_pago_id"
            name="forma_de_pago_id"
            class="form minimal"
            v-model.number="pago.forma_de_pago_id"
            :disabled="cargando"
          >
            <option
              v-for="pago in formasDePago"
              :value="pago.forma_de_pago_id"
              :key="pago.forma_de_pago_id"
            >
              {{ pago.nombre }}
            </option>
          </select>
          <div
          class="form_mensaje_error"
            v-if="errores.forma_de_pago_id !== null"
          >
            {{ errores.forma_de_pago_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="pago_id">Moneda</label>
          <select
            id="moneda_id"
            name="moneda_id"
            class="form minimal"
            v-model.number="pago.moneda_id"
            :disabled="cargando"
          >
            <option
              v-for="pago in monedas"
              :value="pago.moneda_id"
              :key="pago.moneda_id"
            >
              {{ pago.nombre }}
            </option>
          </select>
          <div
          class="form_mensaje_error"
            v-if="errores.moneda_id !== null"
          >
            {{ errores.moneda_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="pago_id">Concepto de Pago</label>
          <select
            id="concepto_id"
            name="concepto_id"
            class="form minimal"
            v-model.number="pago.concepto_id"
            :disabled="cargando"
          >
            <option
              v-for="pago in conceptos"
              :value="pago.concepto_id"
              :key="pago.concepto_id"
            >
              {{ pago.nombre }}
            </option>
          </select>
          <div
          class="form_mensaje_error"
            v-if="errores.concepto_id !== null"
          >
            {{ errores.concepto_id[0] }}
          </div>
        </div>
        <div
          class="form_section"
          v-if="mostrarCampoYear"
        >
          <label for="pago_id">Año del Pago</label>
          <select
            id="year_id"
            name="year_id"
            class="form minimal"
            v-model.number="pago.year_id"
            :disabled="cargando"
          >
            <option
              v-for="pago in years"
              :value="pago.year_id"
              :key="pago.year_id"
            >
              {{ pago.year }}
            </option>
          </select>
          <div
          class="form_mensaje_error"
            v-if="errores.year_id !== null"
          >
            {{ errores.year_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="descripcion">Descripción</label>
          <textarea
            name="descripcion"
            id="descripcion"
            v-model.number="pago.descripcion"
          ></textarea>
        </div>  -->
        <button type="submit" class="boton_guardar">Crear Socio</button>
      </form>
    </div>
  </div>
</template>

<script>
import personasService from '../services/personas'
// import Menu from '../components/Menu.vue'
// import Footer from '../components/Footer.vue'

export default {
  name: 'CrearSocio',
  components: {
  },
  data: function () {
    return {
      perfiles: [],
      certificaciones: [],
      years: [],
      formasDePago: [],
      personas: [],
      monedas: [],
      cargando: false,
      // show: true,
      // seleccionado: 5,
      socio: {
        nombre: null,
        apellido: null,
        email: null,
        perfil_id: null,
        fecha_ingreso: null,
        socio_numero: null,
        socio_id: 1,
        tipo_id: 2,
        certificacion_id: 1
      },
      errores: {
        nombre: null,
        apellido: null,
        email: null,
        dni: null,
        perfil_id: null,
        certificacion_id: null,
        fecha_ingreso: null,
        socio_numero: null
      }
    }
  },
  methods: {
    crearSocio (socio) {
      console.log('qqqq')
      console.log(socio)
      if (this.cargando) {
        return null
      }
      // Reafirmo que el perfil visitante tenga certificacion 1
      if (this.socio.perfil_id === 2) {
        this.socio.certificacion_id = 1
      }
      this.errores = {
        nombre: null,
        apellido: null,
        email: null,
        dni: null,
        perfil_id: null,
        certificacion_id: null,
        fecha_ingreso: null,
        socio_numero: null
      }
      this.cargando = true
      personasService
        .crearSocio(socio)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('SIIIIII')
            this.socio = {
              nombre: null,
              apellido: null,
              email: null,
              perfil_id: null,
              socio_id: 1,
              tipo_id: 2,
              certificacion_id: 1,
              fecha_ingreso: null,
              socio_numero: null
            }
            this.errores = {
              nombre: null,
              apellido: null,
              email: null,
              dni: null,
              perfil_id: null,
              certificacion_id: 1,
              fecha_ingreso: null,
              socio_numero: null
            }
            // this.$router.push('/presupuesto')
          } else {
            this.errores = {
              nombre: null,
              apellido: null,
              email: null,
              dni: null,
              perfil_id: null,
              certificacion_id: 1,
              fecha_ingreso: null,
              socio_numero: null,
              ...respuesta.errors
            }
          }
        })
    }
  },
  mounted: function () {
    console.log('aaaassssssaaaa')
    console.log(this.$route.query.collection_id)
    console.log(this.$route.query.pepe)
    personasService
      .traerPerfilesTodos()
      .then(respuesta => {
        // this.cargando = false
        this.perfiles = respuesta
      })
    personasService
      .traerCertificacionesTodas()
      .then(respuesta => {
        // this.cargando = false
        this.certificaciones = respuesta
      })
  },
  computed: {
    mostrarCampoCertificacion: function () {
      // if (this.pago.concepto_id === 1 ) {
      //   return true
      // }
      // return false
      switch (this.socio.perfil_id) {
        case 1:
          return true
        default:
          return false
      }
    }
  }
}
</script>
