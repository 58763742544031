<template>
  <!-- <tr>
    <td class="borde-derecha">{{ persona.nombre }} {{ persona.apellido }}</td>
    <td>{{ persona.stock_individual }} unidades</td>
  </tr> -->
  <li>{{ persona.nombre }} {{ persona.apellido }}: {{ persona.stock_individual }} unidades</li>
</template>

<script>
// import StockListaItemPersona from '../components/StockListaItemPersona.vue'
// import store from './../store'
// import { PATH_IMG } from '../constants'
// console.log(persona)
export default {
  name: 'StockListaItemPersona',
  components: {
  },
  props: ['persona'],
  data: function () {
    return {
      // store
    }
  }
  // methods: {
  //   bajaProfesor (id) {
  //     console.log(id)
  //     // comerciosService
  //     //   .borrarComercio(id)
  //     //   .then(respuesta => {
  //     //     this.$emit('refrescar')
  //     //     this.store.setStatus({
  //     //       msg: 'El comercio se borro exitosamente',
  //     //       type: 'success banner2'
  //     //     })
  //     //   })
  //   },
  //   abrirModal (id) {
  //     console.log(id)
  //     const confirmation = document.querySelector('.confirmation-' + id)
  //     confirmation.style.display = 'flex'
  //   },
  //   cerrarModal (id) {
  //     const confirmation = document.querySelector('.confirmation-' + id)
  //     confirmation.style.display = 'none'
  //   }
  //   // tipo (tipo) {
  //   //   switch (tipo) {
  //   //     case 1:
  //   //       return 'comercio_tipo_restaurant'
  //   //     case 2:
  //   //       return 'comercio_tipo_hotel'
  //   //     case 3:
  //   //       return 'comercio_tipo_bar'
  //   //   }
  //   //   return 'comercio_tipo_restaurant'
  //   // }
  // },
  // computed: {
  //   // imagenRuta: function () {
  //   //   return `${PATH_IMG}/${this.comercio.imagen}`
  //   // }
  // }
}
</script>

<style>

</style>
