<template>
  <div class="contenedor-cpanel">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <div class="contenedor-titulo">
        <h1>Crear Entrega de Venta</h1>
        <div class="barra"></div>
      </div>
    </section>
    <p>Nombre y Apellido: {{ this.venta.nombre }} {{ this.venta.apellido }}</p>
    <p>Email: {{ this.venta.email }} </p>
    <p>Celular: {{ this.venta.celular }} </p>
    <p>Monto: {{ this.venta.precio_total }} </p>
  </div>
</template>

<script>
// import VentasListaCarrito from '../components/VentasListaCarrito.vue'
import ventasService from '../services/ventas'
export default {
  name: 'CrearEntregaVenta',
  components: {
  },
  data: function () {
    return {
      venta: []
    }
  },
  mounted: function () {
    ventasService
      .traerVentaPorId(this.$route.params.id)
      .then(respuesta => {
        this.venta = respuesta.data
        console.log(respuesta)
      })
  }
}
</script>

<style>

</style>
